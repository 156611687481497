body {
	margin: 0;
	font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #282c34;
	font-size: 12pt;
	color: lightgray;
	padding: 10px;
}

header {
	margin: 0 0 20px;
}
header img {
	max-width: 75px;
	float: left;
	margin: -10px 20px 0 -10px;
}
@media screen and (max-width: 992px) {
	header img {
		max-width: 40px;
		margin: -3px 10px 0 -5px;
	}
}

header h1 {
	margin: 0;
}

header p {
	margin: 0;
	color: gray;
	font-size: 11pt;
}

.inputForm label {
	display: block;
	font-size: 14pt;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;
}

.inputForm label small {
	font-size: 9pt;
	color: gray;
}

input[type='text'] {
	display: block;
	width: 100%;
	max-width: 1120px;
	margin: 5px 0;
	padding: 5px;
	border: none;
	border-radius: 3px;
	font-size: 12pt;
	box-sizing: border-box;
	background-color: lightgray;
}
.inputForm .checkbox {
	margin: 7px 0;
	font-size: 90%;
}
.inputForm .checkbox input {
	vertical-align: top;
	margin: 3px 3px 3px 0;
}

button {
	display: block;
	width: 100px;
	background-color: #527da0; /* #295e8a or #397fb9 */
	color: #fff;
	border: none;
	border-radius: 3px;
	padding: 2px 0;
	font-size: 12pt;
	box-sizing: border-box;
}

.one-taps:before {
	content: 'One Tap:';
	display: block;
	font-size: 14pt;
}
.one-taps {
	margin-bottom: 20px;
}
.one-taps button {
	display: inline-block;
	width: unset;
	padding: 2px 5px;
	margin: 3px 7px 3px 0;
	font-size: 10pt;
}

.messages,
.devices {
	width: 100%;
}

.messages .text {
	min-width: 60%;
	padding-right: 5px;
}

.messages .group,
.messages .date {
	white-space: nowrap !important;
}

tr:nth-child(odd) {
	background-color: #31343b;
}

.messages th,
.devices th {
	text-align: left;
	background-color: #282c34;
}

p.footnote {
	font-size: 70%;
	color: gray;
}

.settings label {
	display: block;
	margin-top: 20px;
}

.settings input {
	margin: 5px 0;
}

.settings h3 {
	margin: 20px 0 5px;
}

.settings .oneTaps table {
	width: 100%;
}
.settings .oneTaps .remove {
	width: 10%;
	text-align: center;
}
.settings .oneTaps a {
	cursor: pointer;
}
.settings .oneTaps small {
	color: gray;
}

.react-tabs__tab-panel {
	min-height: 400px;
}

.react-tabs__tab {
	padding: 10px 30px !important;
}
.react-tabs__tab--selected {
	border-radius: 3px !important;
}

footer {
	position: fixed;
	bottom: 0;
	padding: 5px 0 5px;
	margin: 0 0 0 -10px;
	width: 100%;
	font-size: 8pt;
	color: #a6a6a6;
	text-align: center;
	background-color: #282c34;
}
